import React, { createRef } from "react"
import Styled from "./styled.module.css"
import Slider from "react-slick"
import Quote from "../Icon/quote"
import LeftArrow from "../Icon/Arrow/left-arrow"
import RightArrow from "../Icon/Arrow/right-arrow"
import QuoteSvg from "../../assets/img/quote.svg"
import Hubspot from "../../assets/img/clients/hubspot.svg"
import Airbnb from "../../assets/img/clients/airbnb.png"
import Pattern from "../../assets/img/pattern-3.svg"
import Bookmyshow from "../../assets/img/clients/bookmyshow.png"
import Taxassist from "../../assets/img/clients/tax-assist-logo.png"
import Bigbears from "../../assets/img/clients/big-bears.png"

function Testimonials() {
  const customSlider = createRef(null)
  const settings = {
    customPaging: function (i) {
      return <div className={`${Styled.dot}`}></div>
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: dots => (
      <ul className={Styled.pagination}>
        <button onClick={() => customSlider.current.slickPrev()}>
          <LeftArrow />
        </button>
        {dots}
        <button onClick={() => customSlider.current.slickNext()}>
          <RightArrow />
        </button>
      </ul>
    ),
  }
  return (
    <section id="testimonials">
      <div className={Styled.wrapper}>
        <div className={`${Styled.cardWrapper} hidden-upto-md`}>
          <div className={Styled.cardTitleWrapper}>
            <div>
              <img
                alt="Quote"
                className={Styled.cardTitlePattern}
                src={QuoteSvg}
              />
              <h1 className={Styled.title}>
                Stories from our
                <br /> happy bosses.
              </h1>
              <h2 className={Styled.subtitle}>
                Get inspired by these stories.
              </h2>
            </div>
          </div>
          <div className={Styled.pagination}>
            <LeftArrow />
            <div className={`${Styled.dot} ${Styled.active}`}></div>
            <div className={`${Styled.dot}`}></div>
            <div className={`${Styled.dot}`}></div>
            <div className={`${Styled.dot} `}></div>
            <RightArrow />
          </div>
          <div className={`${Styled.card} ${Styled.first}`}>
            {/* <img alt="Hubspot" src={Hubspot}></img> */}
            <div className={Styled.paragraph}>
              <div>
                <Quote />
              </div>
              <div>
                <p>
                  To quickly start my startup office workflow, I was looking for
                  a outsource company. Integra is one of the best I have come
                  across. They are so flexible, well organised and backed us a
                  lot.
                </p>
                <p className={Styled.personDetails}>Alex Deaton</p>
                <p>Seattle</p>
              </div>
            </div>
          </div>
          <div className={`${Styled.card} ${Styled.second}`}>
            <img alt="Airbnb" src={Taxassist}></img>
            <div className={Styled.paragraph}>
              <div>
                <Quote />
              </div>
              <div>
                <p>
                  Before our firm decided to outsource to Integra, we focussed
                  primarily on the daily accounting jobs. Once we started
                  working together, we found more time to focus our energies on
                  growing
                </p>
                <p className={Styled.personDetails}>Partner</p>
                <p>Tax Assist Accountants, London</p>
              </div>
            </div>
          </div>
          <div className={`${Styled.card} ${Styled.third}`}>
            <img alt="Bookmyshow" src={Bigbears}></img>
            <div className={Styled.paragraph}>
              <div>
                <Quote />
              </div>
              <div>
                <p>Integra helped me build my ecommerce store.</p>
                <p className={Styled.personDetails}>Jane Walker</p>
                <p>Owner, Bigbears Toy Box</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden-from-md">
        <div className={Styled.cardWrapper}>
          <div className={Styled.cardTitleWrapper}>
            <div>
              <img
                alt="Quote"
                className={Styled.cardTitlePattern}
                src={QuoteSvg}
              />
              <h1 className={Styled.title}>
                Stories from our
                <br /> happy bosses.
              </h1>
              <h2 className={Styled.subtitle}>
                Get inspired by these stories.
              </h2>
            </div>
          </div>
        </div>
        <Slider
          className={Styled.sliderWrapper}
          ref={customSlider}
          {...settings}
        >
          <div>
            <div className={`${Styled.card} ${Styled.first} m-auto`}>
              {/* <img alt="Hubspot" src={Hubspot}></img> */}
              <div className={Styled.paragraph}>
                <div>
                  <Quote />
                </div>
                <div>
                  <p>
                    To quickly start my startup office workflow, I was looking
                    for a outsource company. Integra is one of the best I have
                    come across. They are so flexible, well organised and backed
                    us a lot.
                  </p>
                  <p className={Styled.personDetails}>Alex Deaton</p>
                  <p>Seattle</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${Styled.card} ${Styled.second} m-auto`}>
              <img alt="Airbnb" src={Taxassist}></img>
              <div className={Styled.paragraph}>
                <div>
                  <Quote />
                </div>
                <div>
                  <p>
                    Before our firm decided to outsource to Integra, we focussed
                    primarily on the daily accounting jobs. Once we started
                    working together, we found more time to focus our energies
                    on growing
                  </p>
                  <p className={Styled.personDetails}>Partner</p>
                  <p>Tax Assist Accountants, London</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${Styled.card} ${Styled.third} m-auto`}>
              <img alt="Bookmyshow" src={Bigbears}></img>
              <div className={Styled.paragraph}>
                <div>
                  <Quote />
                </div>
                <div>
                  <p>Integra helped me build my ecommerce store.</p>
                  <p className={Styled.personDetails}>Jane Walker</p>
                  <p>Owner, Bigbears Toy Box</p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className={Styled.pattern}>
        <img alt="Pattern" src={Pattern} />
      </div>
    </section>
  )
}

export default Testimonials
