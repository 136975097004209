import React from "react"
import Styled from "./styled.module.css"
import Pattern from "../Icon/pattern"
import Partner1 from "../../assets/img/clients/nasscom.png"
import Partner2 from "../../assets/img/clients/pci-security-standard.png"
import Partner4 from "../../assets/img/clients/iso-27001-3.png"
import Partner3 from "../../assets/img/clients/google-partner.png"
import Partner5 from "../../assets/img/clients/uipath.png"
import Hearts from "../Icon/hearts"
import Stars from "../Icon/stars"

function Section1() {
  return (
    <section className={Styled.section} id="section-1">
      <div className={`${Styled.sectionWrapper} container`}>
        <div className={Styled.ratingsWrapper}>
          <div className={Styled.ratingWrapper}>
            <p className={Styled.rating}>1300+</p>
            <Hearts />
            <div>
              <p className={Styled.ratingText}>Happy Clients</p>
              <p className={Styled.ratingTextSecond}>Since 2004</p>
            </div>
          </div>
          <div className={Styled.ratingWrapper}>
            <p className={Styled.rating}>%93</p>
            <Stars />
            <div>
              <p className={Styled.ratingText}>Service satisfaction</p>
              <p className={Styled.ratingTextSecond}>Across businesses</p>
            </div>
          </div>
        </div>
        <div className={Styled.partnerWrapper}>
          <p className={Styled.partnerText}>
            Trusted by happy bosses<br />
            from 15+ countries around the world,
            since 2004.<br />
          </p>
          <div className={Styled.partners}>
            <p className={Styled.partnerTitle}>Partners & Certificates</p>
            <div className={Styled.parnerImages}>
              <img alt="ISO" src={Partner4}></img>
              <img alt="Nasscom" src={Partner1}></img>
              <img alt="PCI compliance" src={Partner2}></img>
              <img alt="Google partner" src={Partner3}></img>
              <img alt="UI path" src={Partner5}></img>
            </div>
          </div>
        </div>
      </div>
      <Pattern></Pattern>
    </section>
  )
}

export default Section1
