import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/"
import Hero from "../components/Hero/"
import Section1 from "../components/Section1"
import Industries from "../components/Industries"
import Services from "../components/Services"
import Techs from "../components/Techs"
import Testimonials from "../components/Testimonials"
import Organisms from "../components/Organisms"
import Trusted from "../components/Trusted"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/css/normalize.css"
import "../assets/css/custom.css"
import FeaturedImage from "../assets/img/integra-featured-image.png"

export default function Home() {
  return (
    <>
      <Helmet
        title="Integra Global Solutions Corporate Website"
        meta={[
          {
            name: `description`,
            content:
              "Integra Global Solutions Corporate Website. Need help to support and grow your business? Don't look elsewhere, we are a BPO KPO and an IT service provider for companies across USA, UK, Canada, Europe and Australia",
          },
          {
            name: "keywords",
            content:
              "Integra Global Solutions, USA, UK, India, Philippines, Business services, BPO services, KPO services, IT services, business support, business outsourcing services, outsourcing services, small business services, medium and large business services",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Integra Global Solutions Corporate Website"
        />
        <meta
          property="og:description"
          content="Integra Global Solutions Corporate Website. Need help to support and grow your business? Don't look elsewhere, we are a BPO KPO and an IT service provider for companies across USA, UK, Canada, Europe and Australia"
        />
        <meta property="og:image" content={FeaturedImage} />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta
          name="twitter:title"
          content="Integra Global Solutions Corporate Website"
        />
        <meta
          name="twitter:description"
          content="Integra Global Solutions Corporate Website. Need help to support and grow your business? Don't look elsewhere, we are a BPO KPO and an IT service provider for companies across USA, UK, Canada, Europe and Australia"
        />
        <meta name="twitter:image" content={FeaturedImage}></meta>
      </Helmet>
      <Layout>
        <main>
          <Hero />
          <Section1 />
          <Industries />
          <Services />
          <Techs />
          <Testimonials />
          <Organisms />
          <Trusted />
        </main>
      </Layout>
    </>
  )
}
