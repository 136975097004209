import React from "react"

function Stars() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="16"
      fill="none"
      viewBox="0 0 114 16"
    >
      <path
        fill="#F5A623"
        fillRule="evenodd"
        d="M17.665 5.826l-5.745-.78L9.352.188A.413.413 0 009.002 0a.413.413 0 00-.351.187L6.08 5.046l-5.746.78a.385.385 0 00-.316.248.349.349 0 00.099.375l4.158 3.783-.982 5.341a.354.354 0 00.156.357c.12.082.28.093.412.028L9 13.437l5.138 2.52a.415.415 0 00.412-.028c.12-.082.18-.22.156-.357l-.982-5.34 4.158-3.783a.349.349 0 00.099-.375.385.385 0 00-.316-.248zM41.665 5.826l-5.745-.78L33.352.188a.413.413 0 00-.35-.187.413.413 0 00-.351.187l-2.57 4.859-5.746.78a.385.385 0 00-.316.248.349.349 0 00.099.375l4.158 3.783-.982 5.341a.354.354 0 00.156.357c.12.082.28.093.412.028L33 13.437l5.138 2.52a.415.415 0 00.412-.028c.12-.082.18-.22.156-.357l-.982-5.34 4.158-3.783a.349.349 0 00.099-.375.385.385 0 00-.316-.248zM65.665 5.826l-5.745-.78L57.352.188a.413.413 0 00-.35-.187.413.413 0 00-.351.187l-2.57 4.859-5.746.78a.385.385 0 00-.316.248.349.349 0 00.099.375l4.158 3.783-.982 5.341a.354.354 0 00.156.357c.12.082.28.093.412.028L57 13.437l5.138 2.52a.415.415 0 00.412-.028c.12-.082.18-.22.156-.357l-.982-5.34 4.158-3.783a.349.349 0 00.099-.375.385.385 0 00-.316-.248zM89.665 5.826l-5.745-.78L81.352.188a.413.413 0 00-.35-.187.413.413 0 00-.351.187l-2.57 4.859-5.746.78a.385.385 0 00-.316.248.349.349 0 00.099.375l4.158 3.783-.982 5.341a.354.354 0 00.156.357c.12.082.28.093.412.028L81 13.437l5.138 2.52a.415.415 0 00.412-.028c.12-.082.18-.22.156-.357l-.982-5.34 4.158-3.783a.349.349 0 00.099-.375.385.385 0 00-.316-.248zM113.665 5.826l-5.745-.78-2.568-4.859a.413.413 0 00-.351-.187.412.412 0 00-.35.187l-2.57 4.859-5.746.78a.385.385 0 00-.316.248.349.349 0 00.099.375l4.158 3.783-.982 5.341a.354.354 0 00.156.357c.12.082.28.093.412.028L105 13.437l5.138 2.52a.415.415 0 00.412-.028.355.355 0 00.156-.357l-.982-5.34 4.158-3.783a.348.348 0 00.099-.375.386.386 0 00-.316-.248z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Stars
