import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="20"
      fill="none"
      viewBox="0 0 32 20"
    >
      <path
        fill="url(#paint0_linear)"
        d="M3.805 11.237h21.429l-4.708 4.624c-.65.638-.65 1.594 0 2.232a1.59 1.59 0 002.273 0l7.467-7.334c.65-.638.65-1.594 0-2.232L22.8 1.193a1.59 1.59 0 00-2.273 0c-.65.637-.65 1.594 0 2.232l4.708 4.624H3.805c-.811 0-1.623.637-1.623 1.594 0 .957.812 1.594 1.623 1.594z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="30.753"
          x2="3.571"
          y1="18.572"
          y2="-1.195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#624AF2"></stop>
          <stop offset="1" stopColor="#50DDC3"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
