import React from "react"
import TechStack from "../../assets/img/Logos.svg"
import Elements from "../../assets/img/Elements.svg"
import Styled from "./styled.module.css"

function Techs() {
  return (
    <section className={Styled.techs} id="techs">
      <div className={Styled.wrapper}>
        <div className={Styled.textWrapper}>
          <h1 className={Styled.title}>Stabililty first</h1>
          <h2 className={Styled.subtitle}>
            We adapt to<br />
            use your software.<br />
            Always.<br />
          </h2>
          <div className={Styled.divider} />
          <h3 className={Styled.text}>
            We stay updated with working on the latest platforms in your industry. Talk to us today to find out if we can work on your processes
            and platforms.
          </h3>
        </div>
        <img alt="TechStack" className={Styled.techStack} src={TechStack}></img>
        <div className={Styled.elementsWrapper}>
          <img alt="Elements" src={Elements}></img>
        </div>
      </div>
    </section>
  )
}

export default Techs
