import React from "react"

function Hearts() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125"
      height="16"
      fill="none"
      viewBox="0 0 125 16"
    >
      <path
        fill="#FF523D"
        fillRule="evenodd"
        d="M15.595 2.309a4.844 4.844 0 00-7.095.325 4.844 4.844 0 00-7.095-.325 4.734 4.734 0 000 6.731l6.833 6.77a.37.37 0 00.523 0l6.833-6.77a4.733 4.733 0 000-6.731zM37.595 2.309a4.844 4.844 0 00-7.095.325 4.844 4.844 0 00-7.095-.325 4.734 4.734 0 000 6.731l6.833 6.77a.37.37 0 00.523 0l6.833-6.77a4.733 4.733 0 000-6.731zM59.595 2.309a4.844 4.844 0 00-7.095.325 4.844 4.844 0 00-7.095-.325 4.734 4.734 0 000 6.731l6.833 6.77a.37.37 0 00.523 0l6.833-6.77a4.733 4.733 0 000-6.731zM81.595 2.309a4.844 4.844 0 00-7.095.325 4.844 4.844 0 00-7.095-.325 4.734 4.734 0 000 6.731l6.833 6.77a.37.37 0 00.523 0l6.833-6.77a4.733 4.733 0 000-6.731zM103.595 2.309a4.844 4.844 0 00-7.095.325 4.844 4.844 0 00-7.095-.325 4.734 4.734 0 000 6.731l6.833 6.77a.37.37 0 00.523 0l6.833-6.77a4.734 4.734 0 00.001-6.731z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Hearts
