import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="20"
      fill="none"
      viewBox="0 0 32 20"
    >
      <path
        fill="#13304F"
        d="M28.195 8.763H6.766l4.708-4.624c.65-.638.65-1.594 0-2.232a1.59 1.59 0 00-2.273 0L1.734 9.24c-.65.638-.65 1.594 0 2.232L9.2 18.807a1.59 1.59 0 002.273 0c.65-.637.65-1.594 0-2.232l-4.708-4.623h21.429c.811 0 1.623-.638 1.623-1.595s-.812-1.594-1.623-1.594z"
        opacity="0.4"
      ></path>
    </svg>
  )
}

export default Icon
