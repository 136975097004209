import React from "react"
import { Link } from "gatsby"
import Button from "../Button"
import Styled from "./styled.module.css"

function Hero() {
  return (
    <section className={Styled.section}>
      <div className={`${Styled.heroWrapper} container`}>
        <div className={Styled.heroDescription}>
          <div className={Styled.titleWrapper}>
            <h1 className="md-title bold text-gray">
              Your outsourcing success = Our process experts + Our RPA Bot
              automation + Our 15 years outsourcing success
            </h1>
            <p className="lg-text text-gray">
              We have helped 1300+ across the world achieve efficiency, reduce
              cost, grow and gain control of their business through outsourcing
            </p>
            <div className={Styled.heroButtonGroup}>
              <Link to="/contact">
                <Button size="medium">Talk to a consultant</Button>
              </Link>
              <Link to="/services">
                <Button subtle="white" size="medium">
                  Explore our services
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
