import React from "react"

function Pattern() {
  return (
    <svg
      className="pattern"
      xmlns="http://www.w3.org/2000/svg"
      width="119.084"
      height="436.006"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 119.044 119.084 436.006"
      xmlSpace="preserve"
    >
      <linearGradient
        id="SVGID_1_"
        x1="354.748"
        x2="-463.195"
        y1="308.971"
        y2="1082.482"
        gradientTransform="matrix(1 0 0 -1 59.04 1035.4)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#624AF2"></stop>
        <stop offset="1" stopColor="#50DDC3"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M4.281 122.131A270.47 270.47 0 000 119.044V555.05c50.533-75.524 110.217-94.255 118.207-187.81 7.37-86.074-31.817-184.703-113.926-245.109z"
        opacity="0.3"
      ></path>
    </svg>
  )
}

export default Pattern
